@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~quill-emoji/dist/quill-emoji.css';
@import '~quill-mention/dist/quill.mention.min.css';

nz-tabset.ant-tabs {
  //overflow: visible;
}
.ql-container {
  font-size: inherit;
  font-family: inherit;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  //background-color: white;
}

.ql-toolbar.ql-snow {
  background-color: #fafafa;
}

.ql-bubble,
.ql-snow {
  .ql-editor {
    //font-size: 17px;
    //font-weight: 200;

    h1, h2, h3, h4, h5, h6 {
      font-size: inherit;
    }
  }
}

quill-editor .ql-snow .ql-editor {
  //min-height: 100px;
}

quill-editor .ql-container.ql-bubble {
  min-width: 40px;
  text-align: left;
}

quill-view-html .ql-editor > * {
  cursor: unset;
}

// remove padding on inline editor, public view, and preview
quill-view-html,
quill-editor .ql-container.ql-bubble {
  .ql-editor {
    padding: 0;
  }
}

.ql-container {
  &.ql-bubble,
  &.ql-snow {
    //font-family: inherit;
    //font-size: inherit;

    .ql-editor {
      line-height: 1.235;
      //padding: 0;
      text-align: inherit;
    }

    //&.ql-snow .ql-editor a {
    //  text-decoration: none;
    //}

    p {
      margin: 0;
    }
  }
}

/*
aaa-text-view {
  .ql-container {
    font-size: inherit;
  }

  .ql-editor {
    line-height: 1.235;
    padding: 0;
  }

  .ql-indent-1 {
    padding-left: 4.5em;
  }
  .ql-indent-2 {
    padding-left: 7.5em;
  }
  .ql-indent-3 {
    padding-left: 10.5em;
  }
  .ql-indent-4 {
    padding-left: 13.5em;
  }
  .ql-indent-5 {
    padding-left: 16.5em;
  }
  .ql-indent-6 {
    padding-left: 19.5em;
  }
  .ql-indent-7 {
    padding-left: 22.5em;
  }
  .ql-indent-8 {
    padding-left: 25.5em;
  }


}
*/

/*
deprecated
 */
aaa-unrestricted-form {
  quill-editor {
    position: relative;

    .ql-toolbar {
      position: absolute;
      top: 0;
      width: 100%;
      transform: translateY(-100%);

      background-color: #f5f3f3;
    }

    .ql-container.ql-snow {
      border: none;
    }

    .ql-toolbar {
      display: none;
    }

    &.showToolbar .ql-toolbar {
      display: block;
    }
  }
}

.ql-tooltip {
  z-index: 1;
}

.ql-snow .ql-editor {
  blockquote {
    margin: 0;
  }
}

.ql-bubble,
.ql-snow {
  .ql-picker.ql-size {

    .content(@fontSize) {
      .ql-picker-item[data-value="@{fontSize}"]::before,
      .ql-picker-label.ql-active[data-value="@{fontSize}"]::before {
        content: @fontSize;
      }
    }

      //.ql-picker-item[data-value="17px"]::before,
      //.ql-picker-label.ql-active[data-value="17px"]::before {
      //  content: "Default";
      //}

    //.content("9px");
    //.content("10px");
    //.content("12px");
    .content("13px");
    //.content("14px");
    //.content("15px");
    //.content("16px");
    .content("17px");
    //.content("18px");
    //.content("19px");
    //.content("20px");
    //.content("22px");
    .content("24px");
    //.content("26px");
    //.content("28px");
    //.content("30px");
    .content("32px");
    //.content("42px");
    .content("44px");
    //.content("54px");
    .content("60px");

  }
}

quill-editor,
quill-editor .ql-bubble {
  .ql-toolbar {
    .ql-undo {
      color: white;
    }

    .ql-redo {
      color: white;
    }

    .ql-formats,
    &.ql-snow .ql-formats {
      margin-right: 0;
    }
  }
}

.ql-snow.ql-toolbar {
  button,
  .ql-picker-label,
  .ql-picker-item {
    &.ql-redo svg,
    &.ql-undo svg {
      fill: #444;
    }

    &:hover,
    &:focus,
    &.ql-active,
    &.ql-selected {
      &.ql-redo svg {
        fill: #06c;
      }
      &.ql-undo svg {
        fill: #06c;
      }
    }
  }
}

.ql-bubble .ql-toolbar {
  button,
  .ql-picker-label,
  .ql-picker-item {
    &.ql-redo svg,
    &.ql-undo svg {
      fill: #ccc;
    }

    &:hover,
    &:focus,
    &.ql-active,
    &.ql-selected {
      &.ql-redo svg {
        fill: #fff;
      }
      &.ql-undo svg {
        fill: #fff;
      }
    }
  }
}

/*
.ql-bubble .ql-toolbar button:hover .ql-stroke,
.ql-bubble .ql-toolbar button:focus .ql-stroke,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #fff;
}
*/
