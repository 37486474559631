
.ant-btn {
  //border-color: #00a0df;
  //background-color: #00a0df;

  &:hover,
  &:focus {
    color: #025aa5;
    border-color: #025aa5;

    &.no-focus {
      //border-color: #00a0df;
      //background-color: #00a0df;
    }
  }

  &.editMode {
    //border-color: #00a0df;
    //background-color: #00a0df;
    //cursor: default;
  }
}

// popover uses ant-btn-sm, this keeps buttons normal size in popovers
.ant-popover .ant-btn-sm {
  padding: 7.1px 15px;
}

.ant-btn-primary {
  border-color: #00a0df;
  background-color: #00a0df;

  &:hover,
  &:focus {
    color: #fff;
    border-color: #025aa5;
    background-color: #025aa5;

    &.no-focus {
      border-color: #00a0df;
      background-color: #00a0df;
    }
  }

  &.editMode {
    border-color: #00a0df;
    background-color: #00a0df;
    cursor: default;
  }
}

.ant-btn-red {
  border-color: #d43f3a;
  background-color: #d43f3a;

  &:hover,
  &:focus {
    border-color: #cc3333;
    background-color: #cc3333;

    &.no-focus {
      border-color: #d43f3a;
      background-color: #d43f3a;
    }
  }

  &.editMode {
    border-color: #d43f3a;
    background-color: #d43f3a;
    cursor: default;
  }
}


.ant-btn-dark-blue {
  border-color: #0C2B53;
  background-color: #0C2B53;

  &:hover,
  &:focus {
    border-color: #cc3333;
    background-color: #cc3333;

    &.no-focus {
      border-color: #0C2B53;
      background-color: #0C2B53;
    }
  }

  &.editMode {
    border-color: #0C2B53;
    background-color: #0C2B53;
    cursor: default;
  }
}

.ant-btn-medium-blue {
  border-color: #123D74;
  background-color: #123D74;

  &:hover,
  &:focus {
    border-color: #cc3333;
    background-color: #cc3333;

    &.no-focus {
      border-color: #123D74;
      background-color: #123D74;
    }
  }

  &.editMode {
    border-color: #123D74;
    background-color: #123D74;
    cursor: default;
  }
}

.ant-btn-bright-blue {
  border-color: #25538F;
  background-color: #25538F;

  &:hover,
  &:focus {
    border-color: #cc3333;
    background-color: #cc3333;

    &.no-focus {
      border-color: #25538F;
      background-color: #25538F;
    }
  }

  &.editMode {
    border-color: #25538F;
    background-color: #25538F;
    cursor: default;
  }
}

.ant-btn-purple {
  border-color: #88258f;
  background-color: #88258f;

  &:hover,
  &:focus {
    border-color: rgba(136, 37, 143, 0.79);
    background-color: rgba(136, 37, 143, 0.79);

    &.no-focus {
      border-color: #88258f;
      background-color: #88258f;
    }
  }

  &.editMode {
    border-color: #88258f;
    background-color: #88258f;
    cursor: default;
  }
}

.ant-btn-black {
  border-color: #0e0e0e;
  background-color: #0e0e0e;

  &:hover,
  &:focus {
    border-color: rgba(14, 14, 14, 0.82);
    background-color: rgba(14, 14, 14, 0.82);

    &.no-focus {
      border-color: #0e0e0e;
      background-color: #0e0e0e;
    }
  }

  &.editMode {
    border-color: #0e0e0e;
    background-color: #0e0e0e;
    cursor: default;
  }
}

.ant-btn-gray {
  border-color: #808080;
  background-color: #808080;

  &:hover,
  &:focus {
    border-color: #808080;
    background-color: #808080;

    &.no-focus {
      border-color: #808080;
      background-color: #808080;
    }
  }

  &.editMode {
    border-color: #808080;
    background-color: #808080;
    cursor: default;
  }
}

.ant-btn-white.ant-btn-text {
  border-color: transparent;
  background-color: transparent;

  &:hover,
  &:focus {
    border-color: #ffffff;
    //background-color: #ffffff;

    &.no-focus {
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.editMode {
    border-color: transparent;
    background-color: transparent;
    cursor: default;
  }
}

.ant-btn-black.ant-btn-text {
  border-color: transparent;
  background-color: transparent;

  &:hover,
  &:focus {
    border-color: #0e0e0e;
    //background-color: #ffffff;

    &.no-focus {
      border-color: transparent;
      background-color: transparent;
    }
  }

  &.editMode {
    border-color: transparent;
    background-color: transparent;
    cursor: default;
  }
}
