//@import "node_modules/ng-zorro-antd/style/entry.less";
//@import "node_modules/ng-zorro-antd/layout/style/entry.less";
//@import "node_modules/ng-zorro-antd/message/style/entry.less";
//@import "node_modules/ng-zorro-antd/notification/style/entry.less";
//@import "node_modules/ng-zorro-antd/icon/style/entry.less";
//@import "node_modules/ng-zorro-antd/button/style/entry.less";
//@import "node_modules/ng-zorro-antd/select/style/entry.less";

@import "node_modules/ng-zorro-antd/ng-zorro-antd.less";

// experimental -- not part of global ng-zorro-antd.less imports
@import "node_modules/ng-zorro-antd/code-editor/style/entry.less";

@import "./button-colors";

@import "./quill-editor";

// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@font-family: "proxima_nova", sans-serif;
@font-size-base: 16px;
@primary-color: #00a0df;
//@warning-color: #d43f3a;
//@danger-color: #d43f3a;

@height-base: 38px;
@line-height-base: 1.235;
@border-radius-base: 4px;
@border-width-base: 2px;

@link-color: #036ecd;
@black: rgba(35, 35, 35, 0.85);

@form-item-label-font-size: 20px;

//.ant-radio {
//  display: none;
//}

// keep page visible when modal is opened
.cdk-overlay-backdrop.ant-modal-mask {
  opacity: 0;
}

.cdk-drag-preview {
  opacity: .7;
}

// allow page to scroll when modal is opened
html.cdk-global-scrollblock {
  //position: relative;
}

ava-multiblock,
aaa-multiblock,
.cdk-overlay-container {
  a,
  a:hover,
  a:active {
    text-decoration: underline;
  }

  p {
    margin: 0;
  }

  blockquote {
    //border-left: 4px solid #ccc;
    //margin: 0;
    //padding: 0 0 0 16px;
    //counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  }

  .aaa-link-color {
    color: #036ecd;
  }

  .aaa-link-fill {
    fill: #036ecd;
  }

  .ant-form label {
    font-weight: 400;
    font-size: 20px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: unset;
    margin: unset;
    font-size: 17px;
    font-weight: inherit;
  }

}

// override global.less for drupal styles
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

// some weird button overrides to counter a hard-coded !important in the library
a.ant-btn {
  line-height: 1.235;
  padding-top: 7.1px !important;
  text-decoration: none;
}

a.ant-btn-sm {
  line-height: 22px;
  padding-top: .1px !important;
}

a.ant-btn-lg {
  line-height: 38px;
  padding-top: 6.9px !important;
}

.ant-btn {
  height: initial;
}

.ant-modal-body {
  padding: 0;
}

.ant-form-item-label.ant-col {
  //text-align: left;
}

.ant-modal-content,
.ant-modal-header {
  background-color: #fafafa;
  padding: 0;
}

.cdk-overlay-container {
  z-index: 1610;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 8.5px 16px;
}

nz-collapse .ant-collapse-borderless {
  background-color: transparent;
}

nz-card.blue-banner > .ant-card-head {
  background-color: #00529b;
  color: #ffffff;
  font-size: 24px;
}

.settings-form {
  .ant-form-item-control-input,
  .ant-form-item-label > label {
    //height: inherit;
    //min-height: inherit;
  }
}

body {
  margin: 0 auto;
  //line-height: 21px;
  font-size: 17px;

  .columns-container-outer.blue-bg {
    h3,
    h4 {
      color: #fff;
    }
  }

  &.admin-menu.html {
    margin-top: 57px !important;
  }

  .clickable:hover {
    cursor: pointer;
  }

  &.page-user {
    font-weight: 200;

    h1 {
      font-weight: inherit;
    }
  }

  &.page-node-add,
  &.page-node-edit,
  &.page-admin,
  &.page-user.page-user-edit,
  &.page-taxonomy-term-edit {
    font-family: "Lucida Grande", "Lucida Sans Unicode", sans-serif;
    font-weight: 400;
    font-size: 13px;

    fieldset {
      border: 1px solid #ccc;
      padding: 24px 0 0 0;
      margin: 10px 0;

      .fieldset-legend {
        z-index: 1;
      }

      &.collapsed {
        min-height: 44px;
      }
    }

    legend {
      width: unset;
      font-size: 13px;
      //font-size: unset;
    }
  }

  &.page-submit-event {
    h1, .intro-heading-1 {
      margin: 10px 0;
    }
  }

  dl, ol, ul {
    margin-bottom: inherit;
  }

  //footer {
  //  position: relative;
  //  z-index: -1;
  //}
}


body.ngserve {
  max-width: 1440px;
  position: relative;

  .centered, .item-center {
    text-align: center;
  }

  .container, .container-fluid {
    padding-right: .9375rem;
    padding-left: .9375rem;
  }

  .container, .container-fluid {
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 34em) {
    .container {
      max-width: 34rem;
    }
  }

  @media (min-width: 48em) {
    .container {
      max-width: 45rem;
    }
  }

  @media (min-width: 62em) {
    .container {
      max-width: 60rem;
    }
  }

  @media (min-width: 75em) {
    .container {
      max-width: 72.25rem;
    }
  }
}

ava-global-settings,
aaa-global-settings {
  [nzname="marginTop"],
  [nzname="marginBottom"],
  [nzname="paddingTop"],
  [nzname="paddingBottom"] {
    .ant-radio {
      display: none
    }

    .ant-radio-wrapper {
      display: inline-flex;
      font-size: 30px;
      height: 45px;
      width: 45px;
      justify-content: center;
      border: solid 2px transparent;
      border-radius: 3px;
      align-items: center;

      &.ant-radio-wrapper-checked {
        border-color: red;
      }
    }
  }
}

/*
.cdk-overlay-container {
  height: 0;

  .ant-drawer-bottom.ant-drawer-open {
    height: 0;
  }
}
*/
